//.CH
//export const apihost = 'https://apimsa.mysportapp.ch';
//export const apihost = 'https://apidynamic.mysportapp.ch';
//export const apihost = 'https://apitgym.mysportapp.ch';
//export const apihost = 'https://apix30.mysportapp.ch';
//export const apihost = 'https://apiprofitfitness.mysportapp.ch';
//export const apihost = 'https://apigoldsgym.mysportapp.ch';
//export const apihost = 'https://apifitx.mysportapp.ch';
//export const apihost = 'https://apikuoni.mysportapp.ch';
//export const apihost = 'https://apichilihealth.mysportapp.ch';
//export const apihost = 'https://apifitnessunited.mysportapp.ch';
//export const apihost = 'https://apineogate.mysportapp.ch';
//export const apihost = 'https://apichristoppark.mysportapp.ch';
//export const apihost = 'https://apibesttraining.mysportapp.ch';
//export const apihost = 'https://apiquantumgym.mysportapp.ch';
//export const apihost = 'https://apigym365.mysportapp.ch';
//export const apihost = 'https://apitrainiq.mysportapp.ch';
//export const apihost = 'https://apilfit.mysportapp.ch';
export const apihost = 'https://apimeintraining.mysportapp.ch';
//export const apihost = 'https://apifitwork.mysportapp.ch';
//export const apihost = 'https://apifitphysiotitlis.mysportapp.ch';
//export const apihost = 'https://apilifefitness24.mysportapp.ch';
//export const apihost = 'https://apifightindustry.mysportapp.ch';
//export const apihost = 'https://apifitnessemotion.mysportapp.ch';
//export const apihost = 'https://apimoveon.mysportapp.ch';
//export const apihost = 'https://apisportstudio.mysportapp.ch';
//export const apihost = 'https://apifitnessfactory24.mysportapp.ch';
//export const apihost = 'https://apimutschellenfit.mysportapp.ch';
//export const apihost = 'https://apiaesthetikline.mysportapp.ch';
//export const apihost = 'https://apiladyfitness.mysportapp.ch';
//.DE
//export const apihost = 'https://apiphysioplus.mysportapp.ch';
//export const apihost = 'https://apimihusgym.mysportapp.ch';

//WKHTMTOPDF
export const pdfhost = 'https://wkhtmltopdf.kohler-digital.ch';

//VERSION
export const version = '01b.23';
